import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'output', 'select', 'numberfield'];

  static values = {
    visuals: Number,
    logos: Number
  }

  connect() {
    if ($(this.selectTarget).val() != 'Logo') {
      $(".logo-display").addClass('d-none')
    }
  }

  readURL() {
    var input = this.inputTarget
    var output = this.outputTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }
      $(this.outputTarget).removeClass('d-none')
      reader.readAsDataURL(input.files[0]);
    }
  }

  hideIfLogoSelected() {
    if($(this.selectTarget).val() != 'Logo') {
      $(".logo-display").addClass('d-none')
      $(this.numberfieldTarget).val(this.visualsValue)
    } else {
      $(".logo-display").removeClass('d-none')
      $(this.numberfieldTarget).val(this.logosValue)
    }
  }
}
