import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

    static values = {
      url: String,
      id: Number
    }

    static targets = ['resume', 'text', 'copy']

    connect() {
      if (this.hasResumeTarget) {
        $(this.resumeTarget).find('.language-btn').first().addClass('active-translation');
      }
    }

    change_select(event) {
        let params = new URLSearchParams();
        params.append("partial_name", event.target.selectedOptions[0].value);
        get(`${this.urlValue}?${params}`, {
            responseKind: 'turbo-stream'
        })
    }

    change(event) {
        let params = new URLSearchParams();
        let currentTarget = $(event.currentTarget);
        params.append("partial_name", currentTarget.attr('value'))
        $('.button-professionnel').removeClass("bg-blue");
        currentTarget.find('.button-professionnel').addClass("bg-blue")
        get(`${this.urlValue}?${params}`, {
            responseKind: 'turbo-stream'
        })
    }

    changeLanguage(event) {
        let params = new URLSearchParams();
        let currentTarget = $(event.currentTarget);
        $(this.resumeTarget).find('.language-btn').removeClass("active-translation")
        currentTarget.addClass('active-translation')
        params.append('resume', this.idValue )
        params.append('translation', currentTarget.attr('value'))
        params.append('text', currentTarget.attr('value'))
        get(`${this.urlValue}?${params}`, {
            responseKind: 'turbo-stream'
        })
    }

    copyText() {
      var text = this.textTarget.innerText;
      navigator.clipboard.writeText(text);
      var copyMessage = this.copyTarget.dataset.copyText;
      $(this.copyTarget).text(copyMessage);

      setTimeout(() => {
        $(this.copyTarget).text('');
      }, 1500);
    }
}
