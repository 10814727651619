import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        var gaTrackingId = $('#gaTrackingId').data('id')
        window.dataLayer = window.dataLayer || []

        this.gtag('js', new Date());
        this.gtag('config', gaTrackingId)
        this.gtag('current_location', window.location.href)
    }

    gtag() {
        dataLayer.push(arguments);
    }
} 