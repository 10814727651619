// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("@hotwired/turbo-rails")
require("controllers")
global.toastr = require("toastr")

import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"

window.addEventListener("turbo:load", () => {
  new juxtapose.JXSlider('.juxtapose', [
    {
      src: 'https://winespace.s3.eu-west-3.amazonaws.com/image+16+9/Export/Tastee/ipad-wine-reviews-consulting-interface.jpg', alt: "Person consulting wine tasting comments on Tastee from his tablet"
    },
    {
      src: 'https://winespace.s3.eu-west-3.amazonaws.com/image+16+9/Export/Tastee/Ipad-tastee-summary-sheet.jpg', alt: "Person visualizing a wine's taste profile on Tastee from his tablet"
    }
  ], {
    animate: true
  });
});

ActiveStorage.start()

// Images
require.context('../images', true)

$(window).on('load turbo:render', function () {
    if (!$('#gradiant').hasClass('gradiant')) {
        $('.nav_white').toggleClass('nav-black')
    }
});
