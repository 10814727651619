import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal']

  close() {
    $(this.modalTarget).remove();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close()
      toastr.success('Message envoyé')
    } else {
      toastr.warning("Message non envoyé")
    }
  }
}
