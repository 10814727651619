import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['navbar']

    setPositionFixed() {
      if (window.scrollY > 41) {
        $(this.navbarTarget).addClass('fixed');
      } else {
        $(this.navbarTarget).removeClass('fixed');
      }
    }
  }
